@use "src/styles/layout";
@use "src/styles/palette";
@use "src/styles/buttons";
@use "src/styles/layers";

$headerBreakpoint: md;
$domainHeight: 40;

.header {
  position: sticky;
  top: -#{$domainHeight}px;
  background-color: white;
  z-index: layers.$header;
  margin-bottom: 1rem;

  @media (max-width: layout.breakpoint($headerBreakpoint)) {
    top: 0;
    margin-bottom: 0;
  }
}

.domain_wrapper {
  @include layout.container();

  display: flex;
  column-gap: 1rem;
  padding: 0 1rem;
  height: 100%;
  align-items: center;

  .domain_right {
    margin-left: auto;
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }
}

.domain_navigation {
  height: #{$domainHeight}px;
  background-color: palette.grey(light);

  @media (max-width: layout.breakpoint($headerBreakpoint)) {
    display: none;
  }
}

.domain_item {
  text-decoration: none;
  padding: 0.5rem 0;
  font-size: 0.875rem;
  opacity: 0.7;

  &_active {
    font-weight: bold;
    opacity: 1;
  }
}

.toggle_mobilemenu {
  display: flex;
  align-items: center;
  min-height: inherit;
  padding: 1.5rem 1rem;
  margin-left: -1rem;

  > button {
    min-height: inherit;
    width: 1.25rem;
  }

  @media (min-width: layout.breakpoint(md)) {
    display: none;
  }
}

.navigation {
  display: flex;
  width: 100%;
}

.desktop_menuitem {
  padding: 2.5rem 1rem;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  margin-top: 2px;

  svg {
    margin-left: 0.5rem;
  }

  &:hover {
    border-bottom: 2px solid palette.brand("primary");
  }
}

.cta {
  padding: 1rem;
  margin-left: auto;
}

.backdrop {
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);

  &_open {
    @extend .backdrop;

    height: 100%;
  }
}

.header_actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 1rem;

  > a {
    background-color: palette.grey(light);
    color: black;
  }
}
