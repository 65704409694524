@use "src/styles/layout";

.desktop_navigation {
  display: flex;
  width: 100%;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  @media (max-width: layout.breakpoint(md)) {
    display: none;
  }

  &_fade {
    @extend .desktop_navigation;

    opacity: .3;
  }
}

.cta {
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  margin-left: auto;

  >* {
    margin-left: .625rem
  }
}

.content_wrapper {
  @include layout.container();

  display: flex;
  column-gap: 1rem;
}

.desktop_menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 0;
  overflow: hidden;
  background: white;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 0 1rem;

  &_open {
    @extend .desktop_menu;

    height: 416px;
  }
}

.nav_item {
  display: none;
  flex-direction: column;
  row-gap: 1rem;
  width: 25%;
  margin-top: 2rem;

  &_active {
    @extend .nav_item;

    display: flex;
  }
}

.nav_itemlink {
  display: inline-block;
}

.nav_itemtitle {
  @extend .nav_itemlink;

  font-weight: 700;
}