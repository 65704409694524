@use "src/styles/layout";

.highlight {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 1rem;
  @media (max-width: layout.breakpoint(md)) {
    margin-bottom: 1.5rem;
  }
}

.image {
  flex: 1 0 auto;
  &:hover {
    cursor: pointer;
  }
}

.title {
  flex: 1 1 100%;
}