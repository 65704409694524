@use "src/styles/palette";
@use "src/styles/layout";
@use "src/styles/buttons";
@use "src/styles/mixins";

$footerBreakpoint: md;

.footer {
  background-color: palette.brand("black");
  color: palette.$white;
  padding-bottom: 1px;
}

.container {
  @include layout.container();

  padding: 2rem 0;

  @media (max-width: layout.breakpoint($footerBreakpoint)) {
    padding: 1rem 0;
  }
}

.row {
  display: flex;
  flex-direction: row;
  padding: 2rem 0;

  @media (max-width: layout.breakpoint($footerBreakpoint)) {
    flex-direction: column;
    padding: 1rem 0;
  }
}

.column {
  padding: 0 1rem;
  width: calc(100% / 5);

  @media (max-width: layout.breakpoint($footerBreakpoint)) {
    margin-bottom: 1.25rem;
    width: 100%;
  }

  &_list {
    @extend .column;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 1rem;
      }
    }

    @media (max-width: layout.breakpoint($footerBreakpoint)) {
      display: none;
    }
  }

  &_socials {
    @extend .column;

    a {
      margin-bottom: 1rem;
    }

    @media (max-width: layout.breakpoint($footerBreakpoint)) {
      padding-bottom: 1rem;
    }
  }

  @media (max-width: layout.breakpoint($footerBreakpoint)) {
    margin-bottom: 1.25rem;
  }

  &_accordion {
    @extend .column;

    margin-bottom: 0;

    @media (min-width: layout.breakpoint($footerBreakpoint)) {
      display: none;
    }
  }
}

.list_title {
  font-weight: 700;
  margin: 0 0 1rem;
  font-size: 1rem;
}

.column_accordion {
  a {
    color: white;
  }
}

.domain {
  @extend %reset-button;

  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0.25rem;
  padding: 1rem 0;
  display: block;
  text-align: center;
  color: inherit;
  text-decoration: none;

  @media (max-width: layout.breakpoint($footerBreakpoint)) {
    border: none;
    text-align: left;
    padding: 0;
  }

  &_active {
    border: 1px solid rgba(255, 255, 255, 1);

    @media (max-width: layout.breakpoint($footerBreakpoint)) {
      border: none;
      font-weight: 700;
    }
  }
}

.sub_footer {
  color: palette.grey(dark);
  font-size: 0.875rem;
  margin: 0 1rem;

  @media (max-width: layout.breakpoint($footerBreakpoint)) {
    margin-bottom: 1rem;
  }
}

.border {
  margin: 0 1rem;
  height: 1px;
  background: white;

  @media (min-width: layout.breakpoint($footerBreakpoint)) {
    display: none;
  }
}

.accordion {
  padding-top: 1rem;
  @media (min-width: layout.breakpoint($footerBreakpoint)) {
    display: none;
  }
}

.subtext {
  font-size: 1.125rem;
  opacity: 0.6;
}

.socials_title {
  font-weight: 700;
  margin: 0 0 1rem;
  font-size: 1rem;
}

.button {
  @extend %reset-button;
  color: inherit;
  text-decoration: none;
  display: inline-block;
  position: relative;
  font-size: 0.875rem;

  > span {
    @include mixins.text-underline-target();
  }

  &.hover,
  &:hover {
    > span {
      @include mixins.text-underline-trigger();
    }
  }

  &.focus,
  &:focus {
    outline-offset: 0.25rem;
  }
}
