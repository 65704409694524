@use "src/styles/palette";

.social_media {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;

  &_primary {
    @extend .social_media;

    color: palette.brand(primary);
  }

  &_secondary {
    @extend .social_media;

    color: palette.brand(secondary);
  }

  &_black {
    @extend .social_media;

    color: palette.$matte-black;
  }

  &_white {
    @extend .social_media;

    color: palette.$white;
  }
}

.social_media_icon {
  display: flex;
  align-items: center;
  width: 1rem;
  height: 1rem;

  svg {
    width: inherit;
    height: inherit;
  }
}

.handle {
  margin-left: .625rem;
}
