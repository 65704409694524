@use "src/styles/palette";

.container {
  display: flex;
  gap: 0.5rem;
  font-size: 0.875rem;
  align-items: center;

  .toggle {
    cursor: pointer;
    width: 2.5rem;
    height: 1.25rem;
    padding: 0.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      position: absolute;
      cursor: pointer;
      padding: 0;
      margin: 0;
      appearance: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
      border-radius: 9999px;
      transition: 0.3s;
      transform: translateX(0);
    }

    &:has(input:checked):after {
      transform: translateX(calc(100% + 0.25rem));
    }
  }
}

.primary {
  background: palette.$white;
  border: 1px solid palette.brand("black", "base");

  &:has(input:checked) {
    background: palette.brand("black", "base");
  }

  &:after {
    margin: 1px;
    background: palette.brand("black", "base");
  }

  &:has(input:checked):after {
    background: palette.$white;
  }
}

.theme {
  background: palette.brand("black", "base");

  &:after {
    margin: 0.125rem;
    background: palette.$white;
  }
}

[data-theme="750"] {
  .theme {
    &:has(input:checked) {
      background: var(--750-gradient-02);
    }
  }
}
