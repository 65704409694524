@use "src/styles/layers";
@use "src/styles/animations";
@use "src/styles/palette";
@use "src/styles/buttons";
@use "src/styles/mixins";

.skip_link {
  @extend %reset-button;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: transform 0.3s ease-in-out;

  padding: 0.875rem 1.5625rem;
  display: inline-block;
  text-decoration: none;
  font-weight: 800;
  line-height: 1.25;
  border-radius: 2rem;
  transition: background-color 0.25s linear;
  background-color: palette.brand("black");
  color: palette.brand("black", contrast);

  &:hover {
    background-color: palette.brand("black", light);
  }

  &:disabled {
    background-color: palette.brand("black", negative);
  }
}

.skip_link:focus {
  transform: translateX(-50%) translateY(0);
  outline: 3px solid palette.brand("secondary");
  top: 1.5rem;
  z-index: layers.$modal;
}
