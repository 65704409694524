.select {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: .5rem;
  }

  > select {
    appearance: none;
    background-color: transparent;
    border: none;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    color: inherit;

    option {
      background: orange;
    }
  }
}
