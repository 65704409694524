@use "src/styles/layout";
@use "src/styles/palette";
@use "src/styles/typography";
@use "src/styles/buttons";
@use "src/styles/layers";

.locale_select {
  margin-left: auto;
  position: relative;

  @media (max-width: layout.breakpoint(md)) {
    margin-left: 0;
    width: 100%;
  }
}

.locale_select_button {
  @extend %reset-button;

  display: flex;
  align-items: center;
  font-size: 0.875rem;

  svg {
    margin-left: 0.25rem;
    width: 0.875rem;
  }

  &:active {
    color: inherit;
  }
}

.modal {
  position: absolute;
  right: 0;
  background-color: #fff;
  padding: 2rem 3rem 3rem 3rem;
  z-index: layers.$header + 11;
  box-shadow: 0 25px 40px rgba(0, 0, 0, 0.05);
  border: 1px solid palette.grey();
  border-radius: 0.875rem;
  min-width: 500px;
  display: none;

  &_open {
    @extend .modal;

    display: block;
  }
}

.modal_header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  span {
    @extend .header_4;

    margin: 0;
  }

  p {
    margin: 0.25rem 0 0;
    font-size: 0.875rem;
  }

  button {
    @extend %reset-button;

    margin-left: auto;
  }
}

.modal_body {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.75rem;
}

.locale_item {
  padding: 0 0.75rem;
  width: 50%;
}

.locale_button {
  width: 100%;
  text-align: left;
  padding: 0.875rem 0;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: palette.grey();
  transition: border-bottom-color 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &:hover {
    border-bottom-color: palette.$matte-black;
  }

  svg {
    margin-left: auto;
    width: 1.6rem;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  display: none;
  z-index: layers.$header + 10;

  &_open {
    @extend .backdrop;

    display: block;
  }
}
