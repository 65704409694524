@use "src/styles/layout";
@use "src/styles/palette";
@use "src/styles/layers";
@use "src/styles/buttons";
@use "src/styles/animations";

.mobile_menu {
  z-index: layers.$mobileMenu;
  min-height: 0;
  height: 0;
  width: 100%;
  position: fixed;
  transition: min-height animations.$collapse;
  overflow: scroll;
  background-color: palette.$white;

  @media (min-width: layout.breakpoint(md)) {
    display: none;
  }

  &_open {
    @extend .mobile_menu;

    min-height: 100%;
  }
}

%slide {
  width: 100%;
  position: absolute;
  transition: left animations.$collapse;
  padding: 0 1rem 10rem 1rem;
}

.main_menu {
  @extend %slide;

  left: 0;

  &_closed {
    @extend .main_menu;

    left: -100%;
  }
}

%navItem {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;

  > svg {
    width: 0.6875rem;
  }
}

.main_navitem {
  @extend %reset-button;
  @extend %navItem;

  width: 100%;
  font-weight: 700;

  > svg {
    margin-left: auto;
  }
}

.domain_navitem {
  @extend %reset-button;
  @extend %navItem;

  svg {
    margin-left: auto;
    width: 1rem;
  }

  &_active {
    @extend .domain_navitem;

    font-weight: 700;

    svg {
      display: none;
    }
  }
}

.sub_menuback {
  @extend %reset-button;
  @extend %navItem;

  font-weight: 700;

  svg {
    margin-right: 0.5rem;
  }
}

.sub_menu {
  @extend %slide;

  left: 100%;

  &_open {
    @extend .sub_menu;

    left: 0;
  }
}

.nav_itemcontent {
  display: none;
  margin-bottom: 2.5rem;

  &_active {
    display: block;
  }
}

.nav_itemlink {
  margin-bottom: 1.5rem;
  display: block;
}

.nav_itemtitle {
  @extend .nav_itemlink;

  font-weight: 700;
}
