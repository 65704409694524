@use "src/styles/buttons";

.menu {
  @extend %reset-button;
  display: block;
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.bar {
  display: block;
  width: 100%;
  height: 12.5%;
  background-color: currentColor;
  position: absolute;
  transition:
    transform 0.1s linear,
    opacity 0.1s linear;
  border-radius: 1rem;

  &_1 {
    @extend .bar;

    top: 12.5%;
    opacity: 1;

    &.open {
      @extend .bar_1;

      opacity: 0;
    }
  }

  &_2 {
    @extend .bar;

    top: 50%;
    transform: translateY(-50%);

    &.open {
      @extend .bar_2;

      top: calc(50% - 6.25%);
      transform: rotate(45deg);
    }
  }

  &_3 {
    @extend .bar;

    top: 50%;
    transform: translateY(-50%);

    &.open {
      @extend .bar_3;

      top: calc(50% - 6.25%);
      transform: rotate(-45deg);
    }
  }

  &_4 {
    @extend .bar;

    bottom: 12.5%;
    opacity: 1;

    &.open {
      @extend .bar_4;

      opacity: 0;
    }
  }
}
